import React from 'react'

export const Charts = () => {
  return (
    <div  className='h-full w-full   sm:pb-[15px] overflow-scroll lg:overflow-hidden '>
    <div id="chart1" className='sm:mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart2" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart3" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart4" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart5" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart6" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart7" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart8" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart9" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart10" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart11" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart12" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart13" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart14" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart15" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart16" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart17" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart18" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart19" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart20" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart21" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart22" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart23" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart24" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart25" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart26" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart27" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart28" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart29" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
     <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
    <div id="chart30" className='mt-[100px] w-[650px] md:w-[700px] '>
    </div>
    <div className='w-[90%] sm:w-[70%] md:w-[65%] lg:w-[70%] xl:w-[75%] h-1 bg-[#022038] '></div>
 </div>
  )
}
